<template>

  <show-invoices-manager-user ref="invoicesUserRef"></show-invoices-manager-user>

  <el-dialog :lock-scroll="true" :top="'50px'" :width="'60%'" v-model="show" title="Teste">

    <template v-slot:title>
      <div  style="border-bottom: #e0e0e0 1px solid;padding: 20px;">
        <div class="modal-title" style="display: flex;width: calc(100% - 50px)">
          Gestão de Cobranças
        </div>
      </div>
    </template>


    <template v-slot:footer>
      <div  style="border-top: #e0e0e0 1px solid;padding: 20px;display: flex;justify-content: flex-start;">




        <el-button
            @mouseleave="hideTip" @mouseenter.stop="showTip($event,KT('invoice.view'))"
            type="primary" :disabled="selected===0" @click="showUserInvoices()"  >
          <i class="fas fa-file-invoice-dollar"></i>
        </el-button>





        <el-button
            @mouseleave="hideTip" @mouseenter.stop="showTip($event,KT('invoice.unlock'))"
            type="success" :disabled="selected===0"  @click="unlockUser(true)">
          <i class="fas fa-user-lock"></i>
        </el-button>

        <el-button
            @mouseleave="hideTip" @mouseenter.stop="showTip($event,KT('invoice.trustUnlock'))"
            type="warning" :disabled="selected===0"  @click="unlockUser(false)">
          <i class="fas fa-user-lock"></i>
        </el-button>


        <el-button
            @mouseleave="hideTip" @mouseenter.stop="showTip($event,KT('invoice.lock'))"
            type="danger" :disabled="selected===0"  @click="lockUser()">
          <i class="fas fa-user-lock"></i>
        </el-button>





      </div>
    </template>

    <div class="itm" style="display: flex;background: #eeeeee;">


      <div  style="padding: 10px;width: 30px;font-size: 12px;">
        {{KT('user.id')}}

      </div>

      <div  style="flex: 1;padding: 10px;font-size: 12px;text-align: center;">
        {{KT('user.name')}}

      </div>
      <div  style="flex: 1;padding: 10px;font-size: 12px;text-align: center;">
        {{KT('user.email')}}

      </div>
      <div  style="width: 200px;padding: 10px;font-size: 12px;text-align: center;">
        {{KT('invoice.count')}}
      </div>
      <div  style="width: 120px;padding: 10px;font-size: 12px;text-align: center;">
        {{KT('invoice.pending')}}
      </div>
      <div  style="width: 200px;padding: 10px;font-size: 12px;text-align: center;">
        {{KT('invoice.paid')}}
      </div>
    </div>
    <div style="height: calc(100vh - 300px); overflow: hidden; overflow-y: scroll;">



      <div class="itm" v-for="(u,k) in filteredUsers" :key="k" @click="selected = (selected!==u.id)?u.id:0" :class="{tr1: (k%2),tr2: !(k%2),selected: (selected === u.id)}" style="display: flex;">

        <div style="width: 30px;padding: 10px;font-size: 14px;">{{u.id}}</div>
        <div style="flex: 1;padding: 10px;font-size: 14px;text-align: center;">{{u.name}}</div>
        <div style="flex: 1;padding: 10px;font-size: 14px;text-align: center;">{{u.email}}</div>
        <div style="width: 200px;padding: 10px;font-size: 14px;text-align: center;">{{u.invoices.length}}</div>
        <div style="width: 120px;padding: 10px;font-size: 14px;text-align: center;">{{pendingCount(u.invoices)}}</div>
        <div style="width: 190px;padding: 10px;font-size: 14px;text-align: center;">{{paidCount(u.invoices)}}</div>
      </div>

    </div>

  </el-dialog>
</template>


<script setup>




import 'element-plus/es/components/input/style/css'
import 'element-plus/es/components/button/style/css'
import 'element-plus/es/components/switch/style/css'
import 'element-plus/es/components/select/style/css'
import 'element-plus/es/components/option/style/css'
import 'element-plus/es/components/dialog/style/css'
import 'element-plus/es/components/tab-pane/style/css'
import 'element-plus/es/components/tabs/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/checkbox/style/css'

import {ElButton, ElDialog, ElMessageBox} from "element-plus";

import {ref, defineExpose, defineAsyncComponent, computed} from 'vue';


const ShowInvoicesManagerUser = defineAsyncComponent(()=> import("./show-invoices-manager-user.vue") );


const invoicesUserRef  = ref(null);

const selected = ref(0);
const show = ref(false);

import KT from "@/tarkan/func/kt";



const pendingStatus = ['PENDING','OVERDUE'];
const paidStatus = ['RECEIVED_IN_CASH','RECEIVED','CONFIRMED']

const pendingCount = computed(()=>{
  return (invoices)=>{
    return invoices.filter((i)=> pendingStatus.includes(i.status)).length;
  }
})
const paidCount = computed(()=>{
  return (invoices)=>{
    return invoices.filter((i)=> paidStatus.includes(i.status)).length;
  }
})


const unlockUser = (perma)=>{

  const user = filteredUsers.value.find((u)=> u.id == selected.value);

  ElMessageBox.confirm(KT('invoice.confirmUnlock',user)).then(()=>{
    fetch("/tarkan/invoices/manager/" + selected.value + "/unlockUser?perma="+perma).then(() => {
      ElMessageBox.confirm(KT('invoice.confirmUnlockSuccess'));
    });
  });
}

const lockUser = ()=>{

  const user = filteredUsers.value.find((u)=> u.id == selected.value);

  ElMessageBox.confirm(KT('invoice.confirmLock',user)).then(()=>{
    fetch("/tarkan/invoices/manager/" + selected.value + "/lockUser").then(() => {
      ElMessageBox.confirm(KT('invoice.confirmLockSuccess'));
    });
  });
}

const showUserInvoices = ()=>{

  const user = filteredUsers.value.find((u)=> u.id == selected.value);
  invoicesUserRef.value.showInvoices(user);
}



const showTip = (evt,text)=>{
  window.$showTip(evt,text);
}

const hideTip = (evt,text)=>{
  window.$hideTip(evt,text);
}


const filteredUsers = ref([]);




const showInvoices = ()=>{

    show.value = true;

    fetch("/tarkan/invoices/manager").then((response)=>{
      response.json().then((json)=>{
          filteredUsers.value = json;
      })
    });
}


defineExpose({
  showInvoices
});





</script>

<style>

.itm{
  border-bottom: silver 1px dotted;
}

.itm div{
  border-right: silver 1px dotted;
}


.tr1{
  background: #f3f3f3;
}

.tr2{
  background: #f8f8f8;
}

.selected{
  background: rgba(5, 167, 227, 0.05) !important;
}

.itm div:last-child{
  border-right: none;
}

.el-select.el-select--large{
  width: 100%;
}

.el-dialog__header,.el-dialog__body,.el-dialog__footer{
  padding: 0px !important;
}

.el-dialog__footer{
  margin-top: 0px;
}

.el-tabs__nav-wrap{
  padding-left: 20px;
  padding-right: 20px;
}

.el-tabs__content{
  padding-left: 20px;
  padding-right: 20px;
}




.danger{
  --el-button-text-color: var(--el-color-danger) !important;
  --el-button-bg-color: #fef0f0 !important;
  --el-button-border-color: #fbc4c4 !important;
  --el-button-hover-text-color: var(--el-color-white) !important;
  --el-button-hover-bg-color: var(--el-color-danger) !important;
  --el-button-hover-border-color: var(--el-color-danger) !important;
  --el-button-active-text-color: var(--el-color-white) !important;
  --el-button-active-border-color: var(--el-color-danger) !important;
}

</style>